.card {
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    border-radius: 10px;
    background: yellow;
}

.card:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}


.section {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px; 
    border-radius: 5px; 
    background-color: #FFFFFF; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); 
}


.fi-rs-archway {
    font-size: 16px;
    color: #3498db;
    vertical-align: middle;
    margin-right: 1px;
}

.destination-text {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    color: #3498db;
    vertical-align: middle;
}

.bi-pen-fill{
    font-size: 16px;
    color:#EA4335;
}
.review-text{
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    color: #EA4335;;
    vertical-align: middle;
}

  .desc {
    color: #444; 
    font-family: Arial, Helvetica, sans-serif; 
    padding: 8px; 
    margin-top: 4px; 
    white-space: pre-line; 
    border-radius: 4px; 
    font-size: 16px; 
  }
  
.bi-hand-thumbs-up-fill{
    font-size: 16px;
    color:#FFE047 ;
}

.bi-hand-thumbs-down-fill{
    font-size: 16px;
    color:#FFE047 ;
}
.rating-text{
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    color:#FFE047 ;
    vertical-align: middle;
}

.bi-person-circle{
    font-size: 16px;
    color:#1EA362;
}
.info-text{
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    color:#1EA362;
    vertical-align: middle;
}
.label {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #018749;
    font-size: 16px;
    font-weight: 600;
    /* border-bottom: 1px solid #ddd; */
    margin: 5px 0;
}

.desc {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    color: #666;
}

.stars {
    display: flex;
    gap: 5px;
}

.star {
    color: #FFD700;
}

.username, .date {
    font-size: 16px;
    font-family:Arial, Helvetica, sans-serif;
    color: #444;
}

form {
    width: 300px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
    color: #018749;
    background: white;
    padding: 20px;
}

input, textarea {
    padding: 10px;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    transition: border 0.3s ease;
}

input::placeholder, textarea::placeholder {
    font-size: 18px;
    color: #aaa;
}

input:focus, textarea:focus, select:focus {
    border: 1px solid #018749;
}
.customSelect {
    font-size: 14px;
    padding: 8px 10px;
    border: 1px solid #EAEAEA;
    border-radius: 5px;
    background-color: #FBFBFB;
    color: #018749;
    cursor: pointer;
}

.submitButton {
    padding: 5px;
    border-radius: 50px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    background-color: #018749;
    cursor: pointer;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
    border: 2px solid #016937;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    letter-spacing: 0.5px;
    font-weight: bold;
    outline: none;
}
.submitButton i {
    margin-right: 5px;
    font-size: 18px;
  }
  .submitButton:active {
    transform: translateY(1px);
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.submitButton:hover {
    background-color: #016937;
    border-color: #004f25;
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.4);
    transform: translateY(-3px);
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;

  }


.buttons,
.logout {
    position: absolute;
    top: 10px;
    right: 10px;
}


.button {
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}


.button:focus{
    outline: none;
}

 .App {
    font-family:Georgia, 'Times New Roman', Times, serif;
  }
  

  
  .menu-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 15px;
    color: white; 
  }
  
  .menu-button:focus {
    outline: none;
  }
  .toggleButtons {
    position: absolute;
    top: 150px; 
    left: 0;
    background-color: #f4f4f4;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); 
    border-radius: 0 0 5px 5px;
    z-index: 1;
  }
  
  .toggleButtons button {
    display: block;
    width: 100%;
    padding: 10px 15px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    border-bottom: 1px solid #ddd;  
  }
  
  .toggleButtons button:last-child {
    border-bottom: none;  
  }
  
  .toggleButtons button:hover {
    background-color: #e9e9e9;
  }
  

.weather-card {
    width: 250px;
    height: 320px;
    margin: 1px auto; 
    background-color: #25A7E6;  /*#06283D*/
    border-radius: 5px;
    box-shadow: 0 2px 1px 0 rgba(0,0,0,0.2); 
    display: flex;
    flex-direction: column;
    justify-content: space-around; 
    padding: 10px;
    color: #FFFF; 
    font-family:'Segoe UI, sans-serif';
}

.weather-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 215px;
    width:215px;
    margin-top: -40px; 
    font-size: 1rem; 
    /* color: #FFD700;  */
}

.temperature {
    display: flex;
    align-items: baseline;
    justify-content: center; 
    font-size: 2rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 790; 
    line-height: 0.02; 
}

.city-name, .data-container, .text {
    text-align: center; 
    font-weight: 600; 
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin-bottom: 10px; 
    font-size: 1rem;
}

.element {
    display: flex;
    justify-content: center;
    gap: 12px;
    padding: 5px 0;
}

.data {
    font-size: 1rem;
    font-weight: 400; 
}

.icon {
    width: 60%;
    margin: 20px auto;
    display: block; 
}


.weather-card .bottom .wrapper {
    display: flex;
    justify-content: space-between; 
}

.weather-card .humidity, .weather-card .wind-speed {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1; 
    height: 100px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


.weather-card .humidity img{
    width: 35px;
    height: auto; 
    margin-right: 40px; 
}
.weather-card .wind-speed img {
    width: 35px;
    height: auto; 
    margin-right: 10px; 
}

.weather-card .humidity {
    padding-right: 19px; 

}

.weather-card .wind-speed {
    padding-left: 10px; 

}


      .button {
        padding: 10px 20px;
        font-size: 16px;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      
.logout {
        /* Specific styles for the logout button */
        color: white;
        border-radius: 5px;
        background-color: #ff4b5c;
        border-color:#ff4b5c;
        transform: translateY(-3px);
        transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
        color: white;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        cursor: pointer;
        box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        letter-spacing: 0.5px;
        font-weight: bold;
        outline: none;
      }
      
.button.logout:hover {
        background-color: #e43f50;
      }

.login {
    color: white;
    border-radius: 5px;
    margin-right: 5px;
    border-radius: 5px;
    background-color: #ff4b5c;
    border-color:#ff4b5c;
    transform: translateY(-3px);
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    letter-spacing: 0.5px;
    font-weight: bold;
    outline: none;
  }
  
  .register {
    color: white;
    border-radius: 5px;
    margin-right: 5px;
    background-color: #ff4b5c;
    border-color:#ff4b5c;
    transform: translateY(-3px);
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    letter-spacing: 0.5px;
    font-weight: bold;
    outline: none;
    margin-left: 15px; 
  }
  
  .button.login:hover {
    background-color: #e43f50; 
  }
  
  .button.register:hover {
    background-color: #e43f50; 
  }
  